export const openLink = (link: string) => {
  window.open(link, '_blank', 'noopener,noreferrer');
};

export const isValidUrl = (url: string): boolean => {
  const regex = /^(https?:\/\/)?[a-zA-Z0-9\-\\.]+\.[a-zA-Z]{2,6}(\/.*)?$/;

  return regex.test(url);
};

export const formatUrl = (url: string): string => {
  if (url.includes('http')) {
    return url;
  }
  return `http://${url}`;
};
