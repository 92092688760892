import React from 'react';
import dayjs from 'dayjs';
import batteryIcon from 'assets/media/battery.webp';
import wifiIcon from 'assets/media/wifi.webp';

const date = dayjs().format('ddd D MMM h:mm A');
const iconClasses = 'w-5 h-5';

export default function StatusBar() {
  return (
    <div className="flex justify-end items-center space-x-5">
      <img
        className={iconClasses}
        src={batteryIcon}
        alt="Battery"
      />
      <img
        className={iconClasses}
        src={wifiIcon}
        alt="WiFi"
      />
      <span className="text-sm">
        {date}
      </span>
    </div>
  );
}
