import React from 'react';
import clsx from 'clsx';

type Props = {
  className?: string;
  iconClassName?: string;
  title: string;
  icon: string;
  onClick: () => void;
};

export default function File({
  className,
  iconClassName,
  title,
  icon,
  onClick,
}: Props) {
  const classes = clsx('flex flex-col items-center space-y-2 group', className);
  const iconClasses = clsx(
    'h-16 pointer-events-none p-1 rounded group-focus:bg-secondaryHighlight',
    iconClassName,
  );

  return (
    <button
      className={classes}
      type="button"
      onDoubleClick={onClick}
    >
      <img
        className={iconClasses}
        src={icon}
        alt={title}
      />
      <span className="text-sm px-1 rounded group-focus:bg-highlight">
        {title}
      </span>
    </button>
  );
}
