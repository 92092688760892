import React, { ReactNode } from 'react';

type Item = {
  title: string;
  link: string;
  content: ReactNode;
};

const links: Item[] = [
  {
    title: 'Github',
    link: 'https://github.com/imanubhardwaj',
    content: (
      <p>
        I actively contribute on Github. I have repositories containing
        few feature implementation of React, Vue and Node.
      </p>
    ),
  },
  {
    title: 'Medium',
    link: 'https://immanubhardwaj.medium.com',
    content: (
      <p>
        I write tech articles on Medium in my free time. I have amassed over 1 million
        views on my articles and ~70% view to read conversion.
      </p>
    ),
  },
  {
    title: 'StackOverflow',
    link: 'https://stackoverflow.com/users/story/10865921',
    content: (
      <p>
        I also answer questions on StackOverflow every now and then. I have mentioned
        all my PluralSight scores here. Do checkout my Developer Story for more!
      </p>
    ),
  },
];

export default function Social() {
  return (
    <ol className="space-y-2">
      {links.map((link: Item) => (
        <li key={link.title}>
          <a
            className="text-inherit underline"
            href={link.link}
            target="_blank"
            rel="noreferrer"
          >
            {link.title}
          </a>
          {link.content}
        </li>
      ))}
    </ol>
  );
}
