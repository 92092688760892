import React from 'react';
import alerzoIcon from 'assets/media/alerzo.webp';
import instasolvIcon from 'assets/media/instasolv.webp';
import defaultIcon from 'assets/media/default.webp';
import { openLink } from 'utils/helper';
import File from '../File';

export default function Applications() {
  return (
    <div className="flex space-x-12 px-4">
      <File
        iconClassName="p-2"
        title="Alerzo"
        icon={alerzoIcon}
        onClick={() => openLink('https://www.alerzo.com')}
      />
      <File
        iconClassName="p-2"
        title="Instasolv"
        icon={instasolvIcon}
        onClick={() => openLink('')}
      />
      <File
        iconClassName="p-2"
        title="VATC"
        icon={defaultIcon}
        onClick={() => openLink('')}
      />
    </div>
  );
}
