import React, { ReactElement, useState } from 'react';
import Draggable from 'react-draggable';
import clsx from 'clsx';
import applicationsIcon from 'assets/media/applications.webp';
import airdropIcon from 'assets/media/airdrop.webp';
import desktopIcon from 'assets/media/desktop.webp';
import documentsIcon from 'assets/media/documents.webp';
import downloadsIcon from 'assets/media/downloads.webp';
import cloudIcon from 'assets/media/cloud.webp';
import metaIcon from 'assets/media/meta.webp';
import navigationIcon from 'assets/media/navigation.webp';
import ToolbarButtons from '../ToolbarButtons';
import useFullscreen from '../../../useFullscreen';
import Applications from './Applications';
import ResumeContainer from './ResumeContainer';

type Props = {
  zIndex: number;
  onClose: () => void;
};

type TabType = {
  title: string;
  icon: string;
};

const tabs: TabType[] = [
  {
    title: 'Applications',
    icon: applicationsIcon,
  },
  {
    title: 'Airdrop',
    icon: airdropIcon,
  },
  {
    title: 'Desktop',
    icon: desktopIcon,
  },
  {
    title: 'Documents',
    icon: documentsIcon,
  },
  {
    title: 'Downloads',
    icon: downloadsIcon,
  },
];

type TabProps = TabType & {
  activeTab: string;
  setActiveTab: (val: string) => void;
};

function Tab({
  title,
  icon,
  activeTab,
  setActiveTab,
}: TabProps) {
  const tabClasses = clsx(
    'space-x-2 cursor-pointer text-sm p-2 rounded-md flex items-center w-full leading-none',
    { 'bg-secondaryHighlight': title === activeTab },
  );

  return (
    <button
      type="button"
      className={tabClasses}
      onClick={() => setActiveTab(title)}
    >
      <img
        className="w-4 h-4"
        src={icon}
        alt={title}
      />
      <span className="">{title}</span>
    </button>
  );
}

const tabContentMap: Record<string, ReactElement> = {
  Applications: <Applications />,
  Downloads: <ResumeContainer />,
  Documents: <ResumeContainer />,
  Desktop: <ResumeContainer />,
  'iCloud Drive': <ResumeContainer />,
};

export default function Finder({ zIndex, onClose }: Props) {
  const {
    classes,
    fullscreen,
    setFullscreen,
  } = useFullscreen();
  const [activeTab, setActiveTab] = useState<string>('Applications');
  const windowClasses = clsx(classes, '!flex-row w-[50rem] h-[30rem]');

  const content = tabContentMap[activeTab];

  return (
    <Draggable
      bounds="parent"
      handle="#finder-handle"
      defaultPosition={{ x: -400, y: -180 }}
    >
      <div className={windowClasses} style={{ zIndex }}>
        <div className="bg-finderSidebar w-[11rem] px-2 py-4">
          <ToolbarButtons
            className="!ml-3 mb-5 h-max"
            fullscreen={fullscreen}
            onClose={onClose}
            onFullscreenToggle={() => setFullscreen(!fullscreen)}
          />
          <p className="text-gray-500 font-semibold text-xs ml-3 mb-1">
            Favourites
          </p>
          {tabs.map((tab: TabType) => (
            <Tab
              {...tab}
              key={tab.title}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          ))}
          <p className="text-gray-500 font-semibold text-xs ml-3 mb-1 mt-4">
            Locations
          </p>
          <Tab
            icon={cloudIcon}
            title="iCloud Drive"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
        <div className="bg-finderWindowContent flex flex-1 flex-col">
          <div className="flex items-center space-x-1 h-12">
            <img
              className="max-h-full pointer-events-none"
              src={navigationIcon}
              alt="Navigation"
            />
            <span className="flex-1 font-medium text-sm pointer-events-none">
              {activeTab}
            </span>
            <img
              className="max-h-full !mr-2 pointer-events-none"
              src={metaIcon}
              alt="Meta Actions"
            />
          </div>
          <div className="px-4 py-1.5 h-full w-full">
            {content}
          </div>
        </div>
      </div>
    </Draggable>
  );
}
