import createEventBus from 'utils/eventbus';

// eslint-disable-next-line import/prefer-default-export
export const homeEventChannel = createEventBus<{
  openUrl:(url: string) => void;
    }>({
      onError(e) {
        console.error(e);
      },
    });
