import React from 'react';
import logoWhite from 'assets/media/apple_white.webp';
import StatusBar from '../../Accounts/components/StatusBar';

export default function Toolbar() {
  return (
    <div className="flex justify-between items-center px-3 py-0.5 blur-bg !rounded-none !backdrop-blur-[2%]">
      <div className="flex items-center space-x-5">
        <img
          className="h-3.5 w-3"
          src={logoWhite}
          alt="Apple"
        />
        <span className="text-sm">
          Finder
        </span>
        <span className="text-sm">
          File
        </span>
        <span className="text-sm">
          Edit
        </span>
        <span className="text-sm">
          View
        </span>
        <span className="text-sm">
          Go
        </span>
        <span className="text-sm">
          Window
        </span>
        <span className="text-sm">
          Help
        </span>
      </div>
      <StatusBar />
    </div>
  );
}
