import React, {
  ChangeEvent,
  FormEvent,
  useState,
} from 'react';
import clsx from 'clsx';
import backIcon from 'assets/media/arrow_left.webp';
import nextIcon from 'assets/media/arrow_right.webp';

type Props = {
  loading: boolean;
  onBack: () => void;
  onSubmit: (val: string) => void;
};

export default function Auth({
  loading,
  onBack,
  onSubmit,
}: Props) {
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const isLoading = loading && !!password;
  const containerClasses = clsx('flex items-center space-x-2', {
    'animate-shake': error,
  });
  const submitBtnClasses = clsx('rounded-full border-2 border-white invisible', {
    '!visible': password?.length > 0,
  });

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password === 'seasick') {
      onSubmit(password);
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 820);
    }
  };

  const onInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setPassword(target?.value ?? '');
  };

  return (
    <form
      className={containerClasses}
      onSubmit={onFormSubmit}
    >
      {!isLoading && (
        <button
          className="rounded-full border-2 border-white"
          type="button"
          onClick={onBack}
        >
          <img
            className="w-4 h-4"
            src={backIcon}
            alt="Back"
          />
        </button>
      )}
      <div className="flex items-center space-x-2 py-1.5 pl-2 pr-1 blur-bg !bg-blurWhite">
        <input
          className="bg-transparent font-semibold w-32 placeholder:text-white placeholder:text-sm leading-[revert]"
          name="password"
          type="password"
          placeholder="Enter Password"
          value={password}
          onChange={onInputChange}
          disabled={isLoading}
          autoComplete="off"
        />
        <button
          className={submitBtnClasses}
          type="submit"
          disabled={isLoading}
        >
          <img
            className="w-4 h-4"
            src={nextIcon}
            alt="Next"
          />
        </button>
      </div>
      {!isLoading && (
        <button
          type="button"
          className="rounded-full px-1 leading-none text-white border-2 border-white"
        >
          ?
        </button>
      )}
    </form>
  );
}
