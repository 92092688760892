import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase';

export default function useLogger(screenName: string) {
  return (eventName: string, payload: Object = {}) => {
    logEvent(analytics, eventName, {
      firebase_screen: screenName,
      ...payload,
    });
  };
}
