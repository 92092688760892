import React, {
  ChangeEvent,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useState,
} from 'react';

export type Command = {
  id: number;
  content: string | ReactNode;
};

type Props = {
  onSubmit: (command: Command) => void;
};

export default function TerminalInput({ onSubmit }: Props) {
  const [command, setCommand] = useState<string>('');
  const [readonly, setReadonly] = useState<boolean>(false);
  const ref = useCallback((el: HTMLDivElement) => (el ? el.focus() : null), []);

  const onInput = (event: ChangeEvent<HTMLDivElement>) => (
    setCommand(event.target.textContent ?? '')
  );

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setReadonly(true);
      onSubmit({
        id: Date.now(),
        content: command,
      });
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <span className="text-cyan-300 font-semibold">
        Manu-Bhardwaj:~ Visitor$
      </span>
      {/* eslint-disable */}
      <div
        className="cursor-text flex-1 relative placeholder:empty:before:absolute placeholder:empty:before:text-gray-400 placeholder:empty:before:content-[attr(placeholder)]"
        ref={ref}
        contentEditable={!command || !readonly}
        placeholder="Enter help for commands list"
        onInput={onInput}
        onKeyDown={handleKeyPress}
      />
    </div>
  );
}
