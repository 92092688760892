import React, { useEffect } from 'react';
import powerIcon from 'assets/media/power.webp';
import { ScreenProps } from 'types/common';
import useLogger from './useLogger';

export default function Start({ showNextScreen }: ScreenProps) {
  const logEvent = useLogger('init');

  const onStartPortfolio = () => {
    if (document.body.requestFullscreen) {
      logEvent('fullscreen');
      document
        .body
        .requestFullscreen()
        .catch((err) => console.log(err));
    }
    logEvent('show_next_screen');
    showNextScreen();
  };

  useEffect(() => {
    logEvent('screen_view');
  }, []);

  return (
    <button
      className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      type="button"
      onClick={onStartPortfolio}
    >
      <img
        className="h-24"
        src={powerIcon}
        alt="Power"
      />
    </button>
  );
}
