import React from 'react';

export default function Help() {
  return (
    <div>
      GNU bash, version 3.2.57(1)-release (x86_64-apple-darwin19)
      <div className="h-1" />

      These shell commands are defined internally.
      Type &lsquo;help&rsquo; to see this list.
      <div className="h-3" />

      A star (*) next to a name means that the command is disabled.
      <div className="h-3" />

      <div className="pl-8 pr-4 space-y-2">
        <table>
          <tbody>
            <tr>
              <td className="w-32">about</td>
              <td>About Me</td>
            </tr>
            <tr>
              <td className="w-32">projects</td>
              <td>Platforms I&apos;ve built with &#9829;</td>
            </tr>
            <tr>
              <td className="w-32">skills</td>
              <td>Things I&apos;m good at</td>
            </tr>
            <tr>
              <td className="w-32">social-links</td>
              <td>Social communities I contribute to</td>
            </tr>
            <tr>
              <td className="w-32">clear</td>
              <td>Clear the terminal screen</td>
            </tr>
            <tr>
              <td className="w-32">shutdown</td>
              <td>Shutdown</td>
            </tr>
            <tr>
              <td className="w-32">exit</td>
              <td>Exits the Terminal</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
