import { useEffect, useState } from 'react';
import clsx from 'clsx';

type Values = {
  classes: string;
  fullscreen: boolean;
  setFullscreen: (val: boolean) => void;
};

export default function useFullscreen(): Values {
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [useFullscreenClasses, setUseFullscreenClasses] = useState<boolean>(false);

  const classes = clsx(
    'h-[22rem] w-[36rem] flex flex-col overflow-hidden fixed',
    'left-1/2 top-[40%] duration-50 ease-out transition-[width,height]',
    { 'window-transform-f': useFullscreenClasses },
    { 'h-full w-full window-transform-f': fullscreen },
    { 'rounded-lg': !fullscreen },
  );

  useEffect(() => {
    if (fullscreen && !useFullscreenClasses) {
      return setUseFullscreenClasses(true);
    }

    let timeoutId: ReturnType<typeof setTimeout>;
    if (!fullscreen && useFullscreenClasses) {
      timeoutId = setTimeout(() => setUseFullscreenClasses(false), 100);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [fullscreen, useFullscreenClasses]);

  return {
    classes,
    fullscreen,
    setFullscreen,
  };
}
