import React from 'react';
import Draggable from 'react-draggable';
import { Controller, useForm } from 'react-hook-form';
import sendIcon from 'assets/media/send.webp';
import spinner from 'assets/media/spinner.gif';
import dayjs from 'dayjs';
import ToolbarButtons from './ToolbarButtons';
import useFullscreen from '../../useFullscreen';
import useLogger from '../../useLogger';

type Props = {
  zIndex: number;
  onClose: () => void;
};

type FormValue = {
  subject: string;
  content: string;
};

const defaultValues: FormValue = {
  subject: '',
  content: '',
};

const email = 'debug.manu@gmail.com';

export default function Mail({ zIndex, onClose }: Props) {
  const logEvent = useLogger('home');
  const {
    control,
    formState: { isValid, isSubmitting },
    handleSubmit,
    reset,
  } = useForm<FormValue>({
    defaultValues,
    mode: 'all',
  });
  const {
    classes,
    fullscreen,
    setFullscreen,
  } = useFullscreen();

  const onSubmit = async ({ subject, content }: FormValue) => {
    logEvent('send_mail', { subject, content });
    await fetch('https://i-manu-bhardwaj.firebaseio.com/messages.json', {
      body: JSON.stringify({
        subject,
        content,
        date: dayjs().format(),
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
    });
    reset();
  };

  return (
    <Draggable
      bounds="parent"
      handle="#mail-handle"
      defaultPosition={{ x: -288, y: -176 }}
    >
      <div className={classes} style={{ zIndex }}>
        <div
          id="mail-handle"
          className="h-7 bg-windowToolbar relative flex items-center"
        >
          <ToolbarButtons
            fullscreen={fullscreen}
            onClose={onClose}
            onFullscreenToggle={() => setFullscreen(!fullscreen)}
          />
          <button
            className="ml-4 disabled:opacity-50"
            type="button"
            disabled={!isValid || isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            <img
              className="w-3.5 h-3.5"
              src={isSubmitting ? spinner : sendIcon}
              alt="Send"
            />
          </button>
        </div>
        <div className="flex flex-col items-stretch px-2 pb-1 bg-windowContent h-full text-sm">
          <div className="py-1.5 border-b space-x-2">
            <span>To:</span>
            <span>{email}</span>
          </div>
          <div className="py-1.5 border-b space-x-2 flex items-center">
            <span>Subject:</span>
            <Controller
              control={control}
              name="subject"
              rules={{ required: true }}
              render={({ field }) => (
                <input
                  {...field}
                  autoComplete="off"
                  className="flex-1 bg-transparent leading-none"
                  placeholder="Discussion <> Manu"
                  disabled={isSubmitting}
                />
              )}
            />
          </div>
          <Controller
            control={control}
            name="content"
            rules={{ required: true }}
            render={({ field }) => (
              <textarea
                {...field}
                className="flex-1 resize-none bg-transparent my-1.5"
                placeholder="Loved my portfolio? Write me a feedback or ask me anything!"
                disabled={isSubmitting}
              />
            )}
          />
        </div>
      </div>
    </Draggable>
  );
}
