import React, { useEffect, useState } from 'react';
import macBg from 'assets/media/macos-bg.webp';
import Resume from './components/Resume';
import Dock, { Window } from './components/Dock';
import Terminal from './components/Terminal';
import Toolbar from './components/Toolbar';
import Mail from './components/Mail';
import Finder from './components/Finder';
import Safari from './components/Safari';
import { homeEventChannel } from './homeEventChannel';
import useLogger from '../useLogger';

const { pathname } = window.location;
const openResume = pathname === '/ManuBhardwajCV';

export default function Home() {
  const [openWindows, setOpenWindows] = useState<Window[]>([]);
  const [defaultUrl, setDefaultUrl] = useState<string>('');
  const logEvent = useLogger('home');

  const finderIndex = openWindows.indexOf(Window.Finder);
  const terminalIndex = openWindows.indexOf(Window.Terminal);
  const mailIndex = openWindows.indexOf(Window.Mail);
  const safariIndex = openWindows.indexOf(Window.Safari);

  const removeWindow = (window: Window, openWindows: Window[]): Window[] => {
    logEvent('close_window', { name: window });
    return openWindows.filter((item: Window) => item !== window);
  };

  const handleWindowOpen = (name: Window) => {
    logEvent('open_window', { name });
    setOpenWindows((windows: Window[]) => [
      ...removeWindow(name, windows),
      name,
    ]);
  };

  const openUrlInSafari = (url: string) => {
    handleWindowOpen(Window.Safari);
    setDefaultUrl(url);
  };

  useEffect(() => {
    logEvent('screen_view');
    const unsubscribe = homeEventChannel.on('openUrl', (url: string) => {
      openUrlInSafari(url);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (openResume) {
      openUrlInSafari('/ManuBhardwajCV.pdf');
    }
  }, [openResume]);

  return (
    <div
      className="text-white relative w-full h-full bg-center bg-cover"
      style={{ backgroundImage: `url(${macBg})` }}
    >
      <Toolbar />
      <Resume />
      <Dock
        className="fixed bottom-2 left-1/2 -translate-x-1/2"
        openWindows={openWindows}
        onClick={handleWindowOpen}
      />
      {finderIndex !== -1 && (
        <Finder
          zIndex={finderIndex * 2}
          onClose={() => setOpenWindows(removeWindow(Window.Finder, openWindows))}
        />
      )}
      {terminalIndex !== -1 && (
        <Terminal
          zIndex={terminalIndex * 2}
          onClose={() => setOpenWindows(removeWindow(Window.Terminal, openWindows))}
        />
      )}
      {mailIndex !== -1 && (
        <Mail
          zIndex={mailIndex * 2}
          onClose={() => setOpenWindows(removeWindow(Window.Mail, openWindows))}
        />
      )}
      {safariIndex !== -1 && (
        <Safari
          zIndex={safariIndex * 2}
          defaultUrl={defaultUrl}
          onClose={() => {
            setOpenWindows(removeWindow(Window.Safari, openWindows));
            setDefaultUrl('');
          }}
        />
      )}
    </div>
  );
}
