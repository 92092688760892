import React, { useEffect } from 'react';
import appleIcon from 'assets/media/apple_white.webp';
import { ScreenProps } from 'types/common';
import useLogger from './useLogger';

export default function Boot({ showNextScreen }: ScreenProps) {
  const logEvent = useLogger('boot');

  useEffect(() => {
    logEvent('screen_view');
    setTimeout(() => {
      showNextScreen();
      logEvent('show_next_screen');
    }, 5000);
  }, []);

  return (
    <div className="w-full h-full flex flex-col justify-center items-center space-y-24">
      <img
        className="h-24"
        src={appleIcon}
        alt="Apple Logo"
      />
      <div className="h-1.5 w-80 rounded-lg bg-gray-500">
        <div className="h-full bg-white rounded-lg animate-load" />
      </div>
    </div>
  );
}
