import React, {
  Fragment,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import Draggable from 'react-draggable';
import dayjs from 'utils/dayjs';
import folderIcon from 'assets/media/folder.webp';
import TerminalInput, { Command } from './TerminalInput';
import About from './About';
import Projects from './Projects';
import Skills from './Skills';
import Social from './Social';
import Help from './Help';
import ToolbarButtons from '../ToolbarButtons';
import useFullscreen from '../../../useFullscreen';
import useLogger from '../../../useLogger';

const initialState: Command[] = [
  {
    id: Date.now(),
    content: `Last login: ${dayjs().format('ddd MMM DD hh:mm:ss')} on ttys001`,
  },
];

type Props = {
  zIndex: number;
  onClose: () => void;
};

export default function Terminal({ zIndex, onClose }: Props) {
  const logEvent = useLogger('home');
  const [commands, setCommands] = useState<Command[]>(initialState);
  const ref = useRef<HTMLDivElement | null>(null);
  const {
    classes,
    fullscreen,
    setFullscreen,
  } = useFullscreen();

  const handleCommand = ({ id, content }: Command) => {
    logEvent('command', { name: content });
    let reply: string | ReactNode;

    switch (content) {
      case 'about':
        reply = <About />;
        break;
      case 'projects':
        reply = <Projects />;
        break;
      case 'skills':
        reply = <Skills />;
        break;
      case 'social-links':
        reply = <Social />;
        break;
      case 'help':
        reply = <Help />;
        break;
      case 'shutdown':
        window.close();
        break;
      case 'exit':
        onClose();
        break;
      case 'clear':
        setCommands([{ id, content: '' }]);
        return;
      default:
        reply = `-bash: ${content}: command not found`;
    }
    const response = { id, content: reply };
    setCommands((commands: Command[]) => [
      ...commands,
      response,
    ]);
  };

  useEffect(() => {
    const el = ref.current;
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  }, [commands]);

  return (
    <Draggable
      bounds="parent"
      handle="#terminal-handle"
      defaultPosition={{ x: -288, y: -176 }}
    >
      <div className={classes} style={{ zIndex }}>
        <div
          id="terminal-handle"
          className="h-7 bg-windowToolbar relative"
        >
          <ToolbarButtons
            fullscreen={fullscreen}
            onClose={onClose}
            onFullscreenToggle={() => setFullscreen(!fullscreen)}
          />
          <div className="flex items-center space-x-1.5 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <img
              className="w-4 h-4 pointer-events-none"
              src={folderIcon}
              alt="Folder"
            />
            <span className="text-sm">
              manubhardwaj@Portfolio
            </span>
          </div>
        </div>
        <div
          className="bg-windowContent overflow-y-auto px-1 font-Terminal flex-1 text-sm"
          ref={ref}
        >
          {commands.map(({ id, content }: Command) => (
            <Fragment key={id}>
              <div>{content}</div>
              <TerminalInput onSubmit={handleCommand} />
            </Fragment>
          ))}
        </div>
      </div>
    </Draggable>
  );
}
