import React from 'react';
import Draggable from 'react-draggable';
import fileIcon from 'assets/media/file.webp';
import File from './File';
import { homeEventChannel } from '../homeEventChannel';
import useLogger from '../../useLogger';

export default function Resume() {
  // resume width = 64px
  // initial offset = 6px
  const xPos = window.innerWidth - 64 - 12;
  const yPos = 12;
  const logEvent = useLogger('home');

  const openResume = () => {
    logEvent('open_resume');
    homeEventChannel.emit('openUrl', '/ManuBhardwajCV.pdf');
  };

  return (
    <Draggable
      bounds="parent"
      handle="#resume-handle"
      defaultPosition={{ x: xPos, y: yPos }}
    >
      <div
        id="resume-handle"
        className="max-w-max z-20"
      >
        <File
          title="Resume"
          icon={fileIcon}
          onClick={openResume}
        />
      </div>
    </Draggable>
  );
}
