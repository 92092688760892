import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyBgJ6mkYlDcHRW36vNEP3sjeA23f2zZojg',
  authDomain: 'i-manu-bhardwaj.firebaseapp.com',
  databaseURL: 'https://i-manu-bhardwaj.firebaseio.com',
  projectId: 'i-manu-bhardwaj',
  storageBucket: 'i-manu-bhardwaj.appspot.com',
  messagingSenderId: '355299903479',
  appId: '1:355299903479:web:744cf121a08ae7c30310f2',
  measurementId: 'G-3PYHLRZN0M',
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
