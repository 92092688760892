import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import Draggable from 'react-draggable';
import clsx from 'clsx';
import { formatUrl, isValidUrl } from 'utils/helper';
import secureIcon from 'assets/media/secure.webp';
import readIcon from 'assets/media/read.webp';
import backIcon from 'assets/media/back.webp';
import shareIcon from 'assets/media/share.webp';
import addIcon from 'assets/media/add.webp';
import tabsIcon from 'assets/media/tabs.webp';
import spinner from 'assets/media/spinner.gif';
import tripadvisorIcon from 'assets/media/tripadvisor.webp';
import bingIcon from 'assets/media/bing.webp';
import chatgptIcon from 'assets/media/chatgpt.webp';
import wikiIcon from 'assets/media/wikipedia.webp';
import bbcIcon from 'assets/media/bbc.webp';
import ToolbarButtons from './ToolbarButtons';
import useFullscreen from '../../useFullscreen';
import useLogger from '../../useLogger';

type Props = {
  zIndex: number;
  defaultUrl: string;
  onClose: () => void;
};

export default function Safari({
  zIndex,
  defaultUrl,
  onClose,
}: Props) {
  const logEvent = useLogger('home');
  const {
    classes,
    fullscreen,
    setFullscreen,
  } = useFullscreen();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const inputClasses = clsx(
    'px-2 rounded-md bg-[#2A2B2C] w-full h-full text-white text-xs placeholder:text-[#575758]',
    'outline-0 focus:border-[3px] text-center focus:border-sky-700 focus:text-left',
    { 'text-center': search.length === 0 },
  );
  const windowClasses = clsx(
    classes,
    'safari w-[70rem] h-[48rem] top-1/2 left-1/2 flex flex-col',
    { '!w-screen !h-screen !left-0': fullscreen },
  );

  const loadUrl = (url: string, fill: boolean = false) => {
    logEvent('load_url', { url });
    const formattedUrl = url.trim();
    if (fill) {
      setSearch(formattedUrl);
      setLoading(true);
    }
    setTimeout(() => {
      iframeRef.current!.src = formattedUrl;
    }, 0);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      const formattedSearch = search?.trim();
      let url = `https://www.bing.com/search?q=${encodeURI(formattedSearch)}`;
      if (isValidUrl(formattedSearch)) {
        url = formatUrl(formattedSearch);
      }
      loadUrl(url, true);
    }
  };

  const openHome = () => {
    setSearch('');
    setLoading(false);
    setLoaded(false);
  };

  const onLoaded = () => {
    if (iframeRef.current?.src?.length) {
      setLoading(false);
      setLoaded(true);
    }
  };

  const handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value = '' } = event.target;
    setSearch(value);
  };

  useEffect(() => {
    if (defaultUrl.length > 0) {
      loadUrl(defaultUrl, true);
    }
  }, [defaultUrl]);

  useEffect(() => {
    iframeRef.current?.addEventListener('load', onLoaded);

    return () => {
      iframeRef.current?.removeEventListener('load', onLoaded);
    };
  }, []);

  return (
    <Draggable
      bounds="parent"
      handle="#safari-handle"
      defaultPosition={{ x: -550, y: -330 }}
    >
      <div className={windowClasses} style={{ zIndex }}>
        <div
          id="safari-handle"
          className="bg-[#373839] h-[3.25rem] relative flex justify-between items-center"
        >
          <div className="flex items-center h-full">
            <ToolbarButtons
              className="!mx-5"
              fullscreen={fullscreen}
              onClose={onClose}
              onFullscreenToggle={() => setFullscreen(!fullscreen)}
            />
            <img
              className="h-6 mr-4"
              src={readIcon}
              alt="Reading List"
            />
            <button
              className="h-6 w-6 mb-1 mr-2"
              type="button"
              disabled={!loaded}
              onClick={openHome}
            >
              <img
                className={clsx({ 'opacity-30': !loaded })}
                src={backIcon}
                alt="Back"
              />
            </button>
            <button
              className="h-6 w-6 mr-2"
              type="button"
              disabled
            >
              <img
                className="h-6 rotate-180 opacity-30"
                src={backIcon}
                alt="Next"
              />
            </button>
          </div>
          <div className="w-2/5 h-7 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center">
            <img
              className="h-6 mr-2"
              src={secureIcon}
              alt="Secure"
            />
            <input
              className={inputClasses}
              placeholder="Search or enter website name"
              autoComplete="off"
              value={search}
              onKeyDown={handleKeyPress}
              onChange={handleSearchInput}
            />
            {loading && (
              <img
                className="w-3 h-3 absolute right-2 opacity-50"
                src={spinner}
                alt="Spinner"
              />
            )}
          </div>
          <div className="flex items-center space-x-2 mr-4">
            <img
              className="h-6"
              src={shareIcon}
              alt="Share"
            />
            <img
              className="h-6"
              src={addIcon}
              alt="Add Tab"
            />
            <img
              className="h-6"
              src={tabsIcon}
              alt="Tabs"
            />
          </div>
        </div>
        <div className="flex-1 relative">
          {(!loaded || loading || search.length === 0) && (
            <div className="bg-blurBlack w-full h-full absolute">
              <div className="flex flex-col w-3/5 mx-auto">
                <h2 className="mt-12 font-semibold text-white text-[1.5rem]">
                  Favourites
                </h2>
                <div className="flex items-center gap-4 flex-wrap mt-4">
                  <button
                    className="w-16 h-16 rounded overflow-hidden bg-white"
                    type="button"
                    onClick={() => loadUrl('https://www.wikipedia.org', true)}
                  >
                    <img
                      src={wikiIcon}
                      alt="Wikipedia"
                    />
                  </button>
                  <button
                    className="w-16 h-16 rounded overflow-hidden"
                    type="button"
                    onClick={() => loadUrl('https://www.bbc.com', true)}
                  >
                    <img
                      src={bbcIcon}
                      alt="BBC"
                    />
                  </button>
                  <button
                    className="w-16 h-16 px-2 rounded overflow-hidden bg-[#70DDA6]"
                    type="button"
                    onClick={() => loadUrl('https://www.tripadvisor.in', true)}
                  >
                    <img
                      src={tripadvisorIcon}
                      alt="TripAdvisor"
                    />
                  </button>
                  <button
                    className="w-16 h-16 px-1 rounded overflow-hidden bg-white"
                    type="button"
                    onClick={() => loadUrl('https://www.bing.com', true)}
                  >
                    <img
                      src={bingIcon}
                      alt="Bing"
                    />
                  </button>
                  <button
                    className="w-16 h-16 rounded overflow-hidden"
                    type="button"
                    onClick={() => loadUrl('https://chat.openai.com/', true)}
                  >
                    <img
                      src={chatgptIcon}
                      alt="ChatGPT"
                    />
                  </button>
                </div>
              </div>
            </div>
          )}
          <iframe
            ref={iframeRef}
            className={clsx('w-full h-0 absolute z-[2] bg-white', { '!h-full': loaded })}
            title="Browser"
          />
        </div>
      </div>
    </Draggable>
  );
}
