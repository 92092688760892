import React, { useEffect } from 'react';
import macBg from 'assets/media/macos-bg.webp';
import { ScreenProps } from 'types/common';
import StatusBar from './components/StatusBar';
import Users from './components/Users';
import BottomBar from './components/BottomBar';
import useLogger from '../useLogger';

export default function Accounts(props: ScreenProps) {
  const logEvent = useLogger('accounts');
  const { showNextScreen } = props;

  useEffect(() => {
    logEvent('screen_view');
  }, []);

  return (
    <div
      className="text-white relative w-full h-full bg-center bg-cover"
      style={{ backgroundImage: `url(${macBg})` }}
    >
      <div className="w-full flex justify-end px-2 py-1">
        <StatusBar />
      </div>
      <Users showNextScreen={showNextScreen} />
      <BottomBar {...props} />
    </div>
  );
}
