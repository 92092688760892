import React from 'react';
import clsx from 'clsx';
import closeIcon from 'assets/media/close.webp';
import minimizeIcon from 'assets/media/minimize.webp';
import fullscreenIcon from 'assets/media/fs.webp';
import exitFullscreenIcon from 'assets/media/exit_fs.webp';

type Props = {
  className?: string;
  fullscreen: boolean;
  onClose: () => void;
  onFullscreenToggle: () => void;
};

const iconBtnClasses = 'rounded-full h-3 w-3';
const iconClasses = 'w-3/4 mx-auto hidden group-hover:block';

export default function ToolbarButtons({
  className,
  fullscreen,
  onClose,
  onFullscreenToggle,
}: Props) {
  const classes = clsx('flex items-center space-x-2 h-full w-max group ml-2', className);

  return (
    <div className={classes}>
      <button
        className={clsx(iconBtnClasses, 'bg-[#ff3b30]')}
        type="button"
        onClick={onClose}
      >
        <img
          className={iconClasses}
          src={closeIcon}
          alt="Close"
        />
      </button>
      <button
        className={clsx(iconBtnClasses, 'bg-[#ffcc00]')}
        type="button"
        onClick={() => fullscreen && onFullscreenToggle()}
      >
        <img
          className={iconClasses}
          src={minimizeIcon}
          alt="Minimize"
        />
      </button>
      <button
        className={clsx(iconBtnClasses, 'bg-[#34c759]')}
        type="button"
        onClick={onFullscreenToggle}
      >
        <img
          className={clsx(iconClasses, { '!w-2/4': !fullscreen })}
          src={fullscreen ? exitFullscreenIcon : fullscreenIcon}
          alt={fullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
        />
      </button>
    </div>
  );
}
