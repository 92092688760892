import React, { useState } from 'react';
import clsx from 'clsx';
import spinner from 'assets/media/spinner.gif';
import Auth from './Auth';

type Props = {
  className?: string;
  name: string;
  img: string;
  loading: boolean;
  onAccountSelect: () => void;
  onLogin: () => void;
  onResetAccount?: () => void;
  auth?: boolean;
};

export default function User({
  className,
  name,
  img,
  loading,
  onAccountSelect,
  onLogin,
  onResetAccount = () => {},
  auth = false,
}: Props) {
  const [showAuth, setAuth] = useState<boolean>(false);
  const containerClasses = clsx('relative flex flex-col items-center space-y-6 left-0 transition-[left] duration-500 ease-out', className);

  const onImgClick = () => {
    onAccountSelect();
    if (auth) {
      setAuth(true);
    } else {
      onLogin();
    }
  };

  const onBack = () => {
    setAuth(false);
    onResetAccount();
  };

  return (
    <div className={containerClasses}>
      <button
        type="button"
        className="blur-bg overflow-hidden"
        onClick={onImgClick}
      >
        <img
          className="w-32 h-32 object-cover object-top"
          src={img}
          alt={name}
        />
      </button>
      <span>
        {name}
      </span>
      {showAuth && (
        <Auth
          loading={loading}
          onBack={onBack}
          onSubmit={onLogin}
        />
      )}
      {loading && (
        <img
          className="p-1 w-7 h-7 blur-bg"
          src={spinner}
          alt="Spinner"
        />
      )}
    </div>
  );
}
