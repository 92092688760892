import React from 'react';

type Group = {
  title: string;
  skills: string;
};

const skills: Group[] = [
  {
    title: 'Frontend',
    skills: 'TypeScript, JavaScript, HTML, CSS, React, Redux, Hooks, React Native, Next.js, Vue.js, Material UI & TailwindCSS',
  },
  {
    title: 'Backend',
    skills: 'Nodejs, Nest.js, REST APIs, GraphQL, MongoDB & SQL',
  },
  {
    title: 'Cloud',
    skills: 'AWS, Azure, Firebase, Netlify & Vercel',
  },
];

export default function Skills() {
  return (
    <ol className="space-y-2">
      {skills.map(({ title, skills }: Group) => (
        <li key={title}>
          <strong className="underline">{title}</strong>
          <p>{skills}</p>
        </li>
      ))}
    </ol>
  );
}
