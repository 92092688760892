import React from 'react';
import clsx from 'clsx';
import finder from 'assets/media/finder.webp';
import terminal from 'assets/media/terminal.webp';
import mail from 'assets/media/mail.webp';
import safari from 'assets/media/safari.webp';
import bin from 'assets/media/bin.webp';

export enum Window {
  Finder,
  Terminal,
  Mail,
  Safari,
  Bin,
}

type Props = {
  className?: string;
  openWindows: Window[];
  onClick: (windowName: Window) => void;
};

type DockItem = {
  id: Window;
  className?: string;
  disabled?: boolean;
  name: string;
  icon: string;
};

const dockItemOpenClasses = 'after:absolute after:w-[3px] after:h-[3px] after:bg-gray-100 after:rounded-full after:left-1/2 after:-bottom-2 after:-translate-x-1/2';

export default function Dock({
  className,
  openWindows,
  onClick,
}: Props) {
  const dockItems: DockItem[] = [
    {
      className: dockItemOpenClasses,
      id: Window.Finder,
      name: 'Finder',
      icon: finder,
    },
    {
      id: Window.Terminal,
      name: 'Terminal',
      icon: terminal,
    },
    {
      id: Window.Mail,
      name: 'Mail',
      icon: mail,
    },
    {
      id: Window.Safari,
      name: 'Safari',
      icon: safari,
    },
    {
      id: Window.Bin,
      name: 'Bin',
      icon: bin,
      className: '!ml-5 pointer-events-none relative before:content-[""] before:absolute before:-left-2.5 before:h-5/6 before:w-0.5 before:bg-gray-600 before:top-1/2 before:-translate-y-1/2',
    },
  ];

  const containerClasses = clsx(
    'flex items-center space-x-2 blur-bg !rounded-2xl px-2 pt-1 pb-2.5 h-20 z-[2]',
    className,
  );
  const dockItemClasses = 'cursor-pointer bg-transparent p-0 border-0 relative';

  return (
    <div className={containerClasses}>
      {dockItems.map(({
        id,
        name,
        icon,
        className,
      }: DockItem) => {
        const isOpen = id && openWindows.includes(id);
        const classes = clsx(
          dockItemClasses,
          className,
          { [dockItemOpenClasses]: isOpen },
        );

        return (
          <button
            key={name}
            className={classes}
            type="button"
            onClick={() => onClick(id)}
          >
            <img
              className="w-16 h-16 transition-[width,height] ease-out hover:w-20 hover:h-20"
              src={icon}
              alt={name}
            />
          </button>
        );
      })}
    </div>
  );
}
