import React from 'react';

export default function About() {
  return (
    <div>
      Hi Visitor,
      <div className="h-3" />

      I am Manu, Software engineer with 6 years of experience.
      I have been a founding engineer in 3 successful startups
      where I developed products from ground up.
      <div className="h-3" />

      I am also a social contributor, I have over 1 million views on my
      Medium articles. I write primarily about JavaScript. I have over
      1000 reputations on StackOverflow and 5+ public packages live on npm.
      <div className="h-3" />

      I&apos;m based in Dubai but I&apos;m open to working in -4UTC to +2UTC.
      <div className="h-3" />

      Feel free to reach out at
      {' '}
      <a
        className="underline"
        href="mailto:debug.manu@gmail.com"
        target="_blank"
        rel="noreferrer"
      >
        debug.manu@gmail.com
      </a>
      .
      <div className="h-3" />

      Thanks,
      <div className="h-1" />
      Manu
    </div>
  );
}
