import React, { useMemo } from 'react';
import shutdownIcon from 'assets/media/power.webp';
import restartIcon from 'assets/media/restart.webp';
import sleepIcon from 'assets/media/sleep.webp';
import { ScreenProps } from 'types/common';
import useLogger from '../../../useLogger';

type ButtonAttributes = {
  name: string;
  icon: string;
  onClick: () => void;
};

export default function BottomBar({
  showPrevScreen,
  shutdown,
  sleep,
}: ScreenProps) {
  const logEvent = useLogger('accounts');
  const buttons: ButtonAttributes[] = useMemo(() => [
    {
      name: 'Shut Down',
      icon: shutdownIcon,
      onClick: shutdown,
    },
    {
      name: 'Restart',
      icon: restartIcon,
      onClick: showPrevScreen,
    },
    {
      name: 'Sleep',
      icon: sleepIcon,
      onClick: sleep,
    },
  ], [
    showPrevScreen,
    shutdown,
    sleep,
  ]);

  return (
    <div className="absolute bottom-10 left-1/2 -translate-x-1/2 flex justify-end items-center space-x-8 py-1 px-2">
      {buttons.map(({ name, icon, onClick }: ButtonAttributes) => (
        <button
          className="flex flex-col items-center space-y-2 min-w-[5rem]"
          type="button"
          key={name}
          onClick={() => {
            onClick();
            logEvent('btn_click', { name });
          }}
        >
          <img
            className="w-7 h-7 p-1 rounded-full blur-bg"
            src={icon}
            alt={name}
          />
          <span className="text-sm text-gray-300">
            {name}
          </span>
        </button>
      ))}
    </div>
  );
}
