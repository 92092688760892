import React, {
  ComponentType,
  useEffect,
  useState,
} from 'react';
import clsx from 'clsx';
import Start from 'pages/Start';
import Boot from 'pages/Boot';
import Accounts from 'pages/Accounts';
import Home from 'pages/Home';
import { ScreenProps } from 'types/common';

const screens: ComponentType<ScreenProps>[] = [
  Start,
  Boot,
  Accounts,
  Home,
];

const { pathname } = window.location;
const openHome = pathname === '/ManuBhardwajCV';
const hasFullScreen = !!document.body.requestFullscreen;

// eslint-disable-next-line no-nested-ternary
const defaultScreen = openHome ? 3 : hasFullScreen ? 0 : 1;

export default function App() {
  const [activeIndex, setActiveIndex] = useState<number>(defaultScreen);
  const [sleeping, setSleepStatus] = useState<boolean>(false);

  const Screen: ComponentType<ScreenProps> = screens[activeIndex];
  const classes = clsx('w-full h-full transition-opacity ease-out duration-300', {
    'opacity-0 pointer-events-none': sleeping,
    'opacity-100': !sleeping,
  });

  const showNextScreen = () => setActiveIndex((index: number) => index + 1);
  const showPrevScreen = () => setActiveIndex((index: number) => index - 1);
  const shutDown = () => setActiveIndex(0);
  const sleep = () => {
    setTimeout(() => setSleepStatus(true), 0);
  };
  const wakeUp = () => setSleepStatus(false);

  useEffect(() => {
    if (sleeping) {
      document.addEventListener('click', wakeUp);
      document.addEventListener('touchstart', wakeUp);
      document.addEventListener('keydown', wakeUp);
    }

    return () => {
      if (sleeping) {
        document.removeEventListener('click', wakeUp);
        document.removeEventListener('touchstart', wakeUp);
        document.removeEventListener('keydown', wakeUp);
      }
    };
  }, [sleeping]);

  return (
    <div className={classes}>
      <Screen
        showNextScreen={showNextScreen}
        showPrevScreen={showPrevScreen}
        shutdown={shutDown}
        sleep={sleep}
      />
    </div>
  );
}
