import React from 'react';

type Project = {
  title: string;
  techStack: string;
  description: string[];
};

const projects: Project[] = [
  {
    title: 'Alerzo',
    techStack: 'React, React Native, Next.js, GraphQL, Node, MongoDB',
    description: [
      'Managed a team of 6 Engineers to lead 4 Frontend projects',
      'Architected and Developed web and mobile applications',
      'Developed modules like fleet tracking, order management, and inventory management for the back-office application',
      'Developed Authentication and Customer micro-service on the backend',
    ],
  },
  {
    title: 'Instasolv',
    techStack: 'React, Firebase, Node, MongoDB, Redis and ElasticSearch',
    description: [
      'Created live-classroom powered by Twilio to cut down costs by 23%',
      'Catered more than 10k live classes on the platform serving more than 300k students everyday',
      'Developed feature to serve solutions on WhatsApp chat',
      'Developed a course subscription feature end to end for both mobile app and website',
    ],
  },
  {
    title: 'DevsLane',
    techStack: 'React, PHP, Laravel, MySQL',
    description: [
      'Integration with Shopify APIs to help Merchants boost their Sales',
      'Developed in-platform mailing system using Gmail, Outlook, and MailChimp APIs',
      'Added email and video tracking to monitor insights and email designing tool',
    ],
  },
];

export default function Projects() {
  return (
    <ol className="space-y-2">
      {projects.map((project: Project) => (
        <li key={project.title}>
          <strong className="underline">
            {project.title}
          </strong>
          <div className="space-y-1">
            <span>
              Tech Stack:
              {' '}
              {project.techStack}
            </span>
            <ul className="pl-5">
              {project.description.map((val: string) => (
                <li
                  key={val}
                  className="list-disc"
                >
                  {val}
                </li>
              ))}
            </ul>
          </div>
        </li>
      ))}
    </ol>
  );
}
