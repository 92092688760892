import React, { useState } from 'react';
import clsx from 'clsx';
import manu from 'assets/media/manu.webp';
import avatar from 'assets/media/user.webp';
import { ScreenProps } from 'types/common';
import User from './User';
import useLogger from '../../../useLogger';

type Props = Pick<ScreenProps, 'showNextScreen'>;

export default function Users({ showNextScreen }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [account, setAccount] = useState<string>('');
  const isAdmin = account === 'manu';
  const isGuestUser = account === 'guest';
  const logEvent = useLogger('accounts');

  const onLogin = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      showNextScreen();
      logEvent('show_next_screen');
    }, 3000);
  };

  const onAccountSelect = (name: string) => {
    setAccount(name);
    logEvent('account_select', name);
  };

  return (
    <div className="absolute left-1/2 top-[30%] -translate-x-1/2 flex space-x-24">
      <User
        className={clsx({
          '!left-[7.25rem]': !!account,
          'opacity-0 pointer-events-none': !!account && isGuestUser,
        })}
        name="Manu Bhardwaj"
        img={manu}
        loading={loading}
        onAccountSelect={() => onAccountSelect('manu')}
        onLogin={onLogin}
        onResetAccount={() => setAccount('')}
        auth
      />
      <User
        className={clsx({
          '!left-[-6.75rem]': !!account,
          'opacity-0 pointer-events-none': !!account && isAdmin,
        })}
        name="Guest User"
        img={avatar}
        loading={loading}
        onAccountSelect={() => onAccountSelect('guest')}
        onLogin={onLogin}
      />
    </div>
  );
}
