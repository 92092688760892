import React from 'react';
import fileIcon from 'assets/media/file.webp';
import File from '../File';
import { homeEventChannel } from '../../homeEventChannel';
import useLogger from '../../../useLogger';

export default function ResumeContainer() {
  const logEvent = useLogger('home');

  return (
    <File
      title="Resume"
      icon={fileIcon}
      onClick={() => {
        homeEventChannel.emit('openUrl', '/ManuBhardwajCV.pdf');
        logEvent('open_resume');
      }}
    />
  );
}
